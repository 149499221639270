.b-categories_navigation {
	@mixin _navigation-slide($mod: out) {
		@if $mod == out {
			@include media(md-down) {
				height: calc(100% - var(--header-height));
				left: -100%;
				opacity: 0;
				position: fixed;
				top: var(--header-height);
				transition: visibility 0.4s, opacity 0.4s, left 0.4s;
				visibility: hidden;
				width: 100%;
			}

			@include media(md) {
				min-width: 375px;
				width: 50%;
			}
		}

		@if $mod == in {
			@include media(md-down) {
				left: 0;
				opacity: 1;
				transition: visibility, opacity 0.4s, left 0.4s;
				visibility: visible;
			}
		}
	}

	@mixin _navigation-item {
		@include icon(arrow-right-thin, $icon-width: 7px, $icon-height: 13px, $position: after);

		align-items: center;
		display: flex;
		justify-content: space-between;
	}

	padding: rh(0 3);

	//Level 1
	&-list_1 {
		display: flex;
		justify-content: center;

		@include media(lg-up) {
			padding: $configuration-category_navigation-list_1-padding;
		}

		@include media(md-down) {
			flex-direction: column;
		}
	}

	&-item_1 {
		@include media(lg-up) {
			display: flex;
			flex-direction: column;
			padding: rh(0 5);

			&::after {
				border-bottom: 1px solid $color-primary;
				content: '';
				transition: 0.3s width;
				width: 0;
				z-index: 1;
			}

			&:hover::after {
				width: 100%;
			}
		}

		&.m-with_subcategories::before {
			@include media(lg-up) {
				background-color: rgba($color-overlay-background, 0.5);
				content: '';
				height: calc(100vh - var(--header-height));
				left: 0;
				opacity: 0;
				pointer-events: none;
				position: absolute;
				right: 0;
				top: 100%;
				transition: opacity 0.3s linear, visibility 0.3s linear;
				visibility: hidden;
			}
		}

		&.m-with_subcategories:hover::before {
			opacity: 1;
			visibility: visible;
		}

		& + & {
			@include media(md-down) {
				border-top: 1px solid $color-mobile_navigation-categories_divider;
			}
		}
	}

	// Link 1 styles
	&-link_1 {
		display: inline-block;
		font: $configuration-category_navigation-link-font;
		letter-spacing: $configuration-category_navigation-link-letter_spacing;
		text-transform: $configuration-category_navigation-link-text_tranform;

		@include media(lg-up) {
			padding: rh(0 0 4);
		}

		@include media(md-down) {
			@include _navigation-item;

			padding: rh(5 2);
		}

		&:hover {
			text-decoration: none;
		}
	}

	// EO Link 1 styles
	&-back_link {
		background-color: $color-hamburger_back_link-background;
		border-bottom: 1px solid $color-mobile_navigation-categories_divider;
		color: $color-primary-background;

		@include media(lg-up) {
			display: none;
		}
	}

	&-back_link_cta {
		align-items: center;
		display: flex;
		font-size: 16px;
		padding: rh(5);
		text-transform: uppercase;
		width: 100%;

		@include icon(arrow-left-thin, $icon-width: 7px, $icon-height: 13px) {
			margin-right: rh(3);
		}
	}

	&-show_all_products {
		display: block;
		padding: rh(4 6);
		text-transform: uppercase;

		@include media(lg-up) {
			display: none;
		}
	}

	// Group 1 styles
	&-group_1 {
		@include _navigation-slide(out);

		@include media(lg-up) {
			background: $color-navigation_flyout-background;
			border-top: 2px solid $color-main_nav-divider;
			left: 0;
			margin-top: -2px;
			opacity: 0;
			padding: rh(5 0 10);
			position: absolute;
			right: 0;
			top: 100%;
			transition: opacity 0.3s;
			visibility: hidden;
		}

		@include media(md-down) {
			background: $color-hamburger_menu-background;
			overflow: auto;
			overscroll-behavior: contain;
			z-index: 1;
		}
	}

	&-list_2 {
		@include media(lg-up) {
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			padding: rh(0 10);
		}
	}

	&-list_2 > &-item_2.m-view_all {
		@include media(lg-up) {
			display: none;
		}
	}

	&-link_2 {
		@include media(lg-up) {
			display: block;
			font-size: 16px;
			font-weight: 600;
			line-height: 25px;
			margin-bottom: rh(3);
			text-transform: uppercase;
		}
	}

	&-item_1:hover &-group_1,
	&-group_1[aria-hidden='false'] {
		@include media(lg-up) {
			opacity: 1;
			visibility: visible;
		}
	}

	&-item_1.m-active &-group_1 {
		@include _navigation-slide(in);
	}

	// Group 2 styles (and 3)
	&-group_2 {
		@include _navigation-slide(out);

		background: $color-navigation_flyout-background;

		@include media(md-down) {
			background: $color-hamburger_menu-background;
			overflow: auto;
			overscroll-behavior: contain;
			z-index: 1;
		}
	}

	&-item_2.m-active &-group_2 {
		@include _navigation-slide(in);
	}

	&-list_2,
	&-list_3 {
		@include media(md-down) {
			padding: rh(0 3);
		}
	}

	&.m-content_only &-list_2 {
		display: block;
	}

	&.m-3_col &-list_2 {
		@include media(lg-up) {
			grid-template-columns: repeat(6, 1fr);
		}
	}

	&.m-1_col &-list_2 {
		@include media(lg-up) {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&-item_3 {
		@include media(lg-up) {
			margin-bottom: rh(2);
		}
	}

	&-item_2:not(.m-view_all),
	&-item_3:not(.m-view_all) {
		@include media(md-down) {
			border-bottom: 1px solid $color-mobile_navigation-categories_divider;
		}
	}

	&-link_2,
	&-link_3 {
		@include media(md-down) {
			font-size: 16px;
			line-height: 1;
			padding: rh(6 2);
			text-transform: uppercase;
		}

		&.m-view_all {
			@include t-link_1;

			display: inline-block;

			@include media(md-down) {
				margin-left: rh(2);
				padding-left: 0;
				padding-right: 0;
				text-transform: none;
			}
		}
	}

	&-link_3 {
		@include media(lg-up) {
			font-size: 14px;
			line-height: 25px;
		}
	}

	&-link_2:not(.m-view_all),
	&-link_3:not(.m-view_all) {
		@include media(md-down) {
			@include _navigation-item;
		}
	}

	// flyout with content asset
	&-group_1.m-custom_menu {
		@include media(lg-up) {
			display: grid;
			padding: rh(5 10 10);
		}

		&.m-1x1 {
			@include media(lg-up) {
				grid-template-columns: 1fr 1fr;
			}
		}

		&.m-1x2 {
			@include media(lg-up) {
				grid-template-columns: 1fr 2fr;
			}
		}
	}

	&-group_1.m-custom_menu &-list_2 {
		@include media(lg-up) {
			padding: rh(0 4 0 0);
		}
	}

	&-group_1.m-1x1 &-list_2 {
		@include media(lg-up) {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&-group_1.m-1x2 &-list_2 {
		@include media(lg-up) {
			grid-template-columns: 1fr;
		}
	}
}
